// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// import { Controller } from "stimulus"
// import StimulusReflex from 'stimulus_reflex'

import ApplicationController from './application_controller'

export default class extends ApplicationController {
  reboot_enc1(event) {
    if (confirm("Rebooting ENCODER 1, are you sure?")) {
      this.stimulate("Encoder#reboot_enc1", event.target)
    }
  }

  reboot_enc2(event) {
    if (confirm("Rebooting ENCODER 2, are you sure?")) {
      this.stimulate("Encoder#reboot_enc2", event.target)
    }
  }
}
